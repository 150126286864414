@import "../common/config.scss";

.sluzba-karta-wrapper {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #626a6e;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2 {
    font-weight: 700;
    font-size: 1.25em;
    line-height: 30px;
    margin: 0;
  }
  p {
    margin: 0;
  }
  h3 {
    font-weight: 700;
    margin: 0;
  }
}

.row-1 {
  display: grid;
  grid-template-columns: 10% 89%;
  gap: 1%;
}

.logo img {
  width: 90%;
  mix-blend-mode: multiply;
}

.row-2 {
  display: grid;
  grid-template-columns: 10% 89%;
  gap: 1%;
}

.row-2-right {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 2%;
}

.row-2-box {
  padding: 0.5rem;
  background-color: #f7f8fb;
}

.klucove-slova-wrapper {
  background-color: #f7f8fb;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.klucove-slova {
  padding: 10px 15px;
  background: #0065b3;
  border-radius: 10px;
  font-size: 1em;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
}

.button-wrapper {
  margin-top: 2rem;
  text-align: end;
}
.button {
  background: #00703c;
  padding: 9px 12px;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.protokoly a {
  text-decoration: none;
  display: flex;
}

.protokoly-btn {
  color:black;
}

@media only screen and (max-width: $show-medium) and (min-width: $show-mobile) {
}

@media only screen and (max-width: $show-mobile) {
  .row-2 {
    grid-template-columns: auto;
  }
  .row-2-right {
    grid-template-columns: auto;
  }
  .klucove-slova-wrapper {
    display: grid;
    grid-template-columns: auto;
  }
  .row-1 {
    display: grid;
    grid-template-columns: auto;
  }
  .logo img {
    width: 75px;
  }
}
