.page {
  padding: 0 1rem;
}

.page-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
