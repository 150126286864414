.title-black {
  padding-top: 1rem;
  padding-left: 0.5rem;
  display: grid;
  grid-template-columns: 1rem auto;
  gap: 1rem;
}
.title-black div {
  background-color: black;
  width: 0.75rem;
  height: 1.5rem;
  border-radius: 0 1.5rem 1.5rem 0;
}
.row-content {
  margin-left: 2rem;
}
.dataset-wrapper {
  margin: 2rem;
}
