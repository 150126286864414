.webservices-wrapper {
  border-left: 1px solid #0065b3;
}

.title-blue {
  display: grid;
  grid-template-columns: 1rem auto;
  align-items: center;
  gap: 1rem;

  div {
    background-color: #0065b3;
    width: 1.5rem;
    height: 3rem;
    border-radius: 0 3rem 3rem 0;
  }

  h2 {
    margin: 0;
    font-weight: 400;
    font-size: 1.5em;
    line-height: 30px;
    color: #0065b3;
  }
}
