@import "../common/config.scss";

.container {
  margin: 1rem;
  padding-top: 1rem;
}

@media only screen and (max-width: $show-medium) and (min-width: $show-mobile) {
}

@media only screen and (max-width: $show-mobile) {
  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
