@import "../common/config.scss";

.title {
  margin: 0 1rem;
}

.subtitle {
  margin: 0;
  margin-bottom: 2rem;
  margin-top: -1rem;
  padding-left: 1rem;

  font-weight: 500;
}
