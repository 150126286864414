@import "../common/config.scss";

.content {
  display: grid;
  grid-template-columns: 30% auto;
  gap: 4rem;
}

.content h3 {
  margin: 0;
  margin-bottom: 1rem;
}

.content p {
  margin: 0;
  margin-bottom: 1rem;
}

.list-wrapper {
  margin: 1rem;
}

@media only screen and (max-width: $show-medium) and (min-width: $show-mobile) {
  .content {
    grid-template-columns: auto;
    gap: 0rem;
  }
}

@media only screen and (max-width: $show-mobile) {
  .content {
    grid-template-columns: auto;
    gap: 0rem;
  }
}
