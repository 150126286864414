@import "../common/config.scss";

.search-panel-wrapper {
  display: grid;
  grid-template-columns: auto;
}

.pocet-vysledkov-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.pocet-vysledkov {
  color: #f47738;
  font-size: 1em;
}

@media only screen and (max-width: $show-medium) and (min-width: $show-mobile) {
  .search-panel-wrapper {
    display: grid;
    grid-template-columns: auto auto;
  }
  .search-main {
    grid-template-columns: auto;
  }
}

@media only screen and (max-width: $show-mobile) {
  .search-panel-wrapper {
    display: grid;
    grid-template-columns: auto;
  }
  .search-main {
    grid-template-columns: auto;
  }
}
