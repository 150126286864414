@import "../common/config.scss";

.sluzba-karta {
  margin-top: 1rem;
  border-top: 1px solid #626a6e;
}

.sluzba-karta b {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.card-top {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 200px auto;
  gap: 1rem;
}

.info-topright * {
  margin: 0;
}

.info-url-link {
  font-weight: 400;
  font-size: 1em;
  line-height: 20px;
  margin-bottom: 0.5rem;

  a {
    color: black;
    margin-left: 5px;
  }
}

.text-top-right {
  font-weight: 400;
  font-size: 1em;
  line-height: 25px;
}

.section-wrapper {
  margin-top: 1rem;
  display: grid;
  align-items: center;
  grid-template-columns: 185px auto;
  gap: 1rem;
  padding: 15px;
  background: #f7f8fb;

  h4 {
    width: 185px;
    font-size: 1em;
    margin-top: 20px;
  }
}

.klucove-slova {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  p {
    margin: 0;
    padding: 5px 15px;
    padding-bottom: 7px;
    background: #0065b3;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: lighter;
  }
}

.kontakt-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 15px;
  background: #f7f8fb;
  margin-top: 1rem;

  h4 {
    width: 185px;
    font-size: 1em;
  }
}

.kontakt-right-title {
  font-weight: 400;
  line-height: 20px;
  color: #0065b3;
  margin: 0;
}

.kontakt-right-subtitle {
  margin: 0;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #626a6e;
}

.kontakt-line {
  margin: 0;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 400;
  font-size: 0.85em;
  line-height: 20px;
  color: #626a6e;

  p {
    margin: 0;
  }
}

.protokoly {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 10px;

  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  p {
    margin: 0;
    font-size: 1em;
    color:#0A538F ;
  }

  img {
    height: 1em;
  }
}

.protokoly-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

@media only screen and (max-width: $show-medium) and (min-width: $show-mobile) {
  .protokoly {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width: $show-mobile) {
  .protokoly {
    display: grid;
    grid-template-columns: auto;
  }
  .card-top {
    grid-template-columns: auto;
  }
  .section-wrapper {
    grid-template-columns: auto;
  }
  .kontakt-wrapper {
    display: grid;
    grid-template-columns: auto;
  }
  .protokoly {
    grid-template-columns: auto;
  }
}
