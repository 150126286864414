@import "../common/config.scss";

.list-of-sources {
  display: grid;
  gap: 1rem;
  grid-template-columns: auto;
}

@media only screen and (max-width: $show-medium) and (min-width: $show-mobile) {
}

@media only screen and (max-width: $show-mobile) {
}
