@import "../common/config.scss";

.source-page {
  margin-top: 2rem;
}

.title {
}

.description {
}

@media only screen and (max-width: $show-medium) and (min-width: $show-mobile) {
}

@media only screen and (max-width: $show-mobile) {
  .source-page {
    margin-top: 6rem;
  }
}
