@import "../common/config.scss";

.breadcrumbs {
  height: 3rem;
  margin: 1rem 0;
}

.breadcrumbs p {
  margin: 0;
  margin-top: -3px;
}

.breadcrumbs,
.breadcrumbs > a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  color: black !important;
  border-bottom: 3px solid white;
}

.breadcrumbs a:hover {
  border-bottom: 3px solid black;
  text-decoration: none;
}

.icon-home {
  font-size: 0.85em;
  margin-bottom: -4px;
}

.icon {
  font-size: 0.8em;
}

@media only screen and (max-width: $show-medium) and (min-width: $show-mobile) {
  .breadcrumbs {
    margin-top: 1rem;
    height: auto;
  }

  .breadcrumbs,
  .breadcrumbs > a {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: $show-mobile) {
  .breadcrumbs {
    height: auto;
    margin-top: 10rem;
  }

  .breadcrumbs,
  .breadcrumbs > a {
    flex-wrap: wrap;
    display: none;
  }
}
