@import "../common/config.scss";

.page {
  margin: 0 1rem;
}

@media only screen and (max-width: $show-mobile) {
  .page {
    margin-top: 6rem;
  }
}
