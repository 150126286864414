@import "../common/config.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  font-size: 1rem;
  line-height: 140%;
  margin-bottom: 1rem;
}

@media only screen and (max-width: $show-mobile) {
  .wrapper {
    margin-top: 6rem;
  }
}
