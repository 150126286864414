@import "./common/config.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.idsk-search-component {
  margin: 0;
}

.govuk-width-container {
  max-width: 1280px;
}
.idsk-search-results--expand .idsk-search-results__list {
  max-height: 500px;
}

.govuk-accordion__section-heading,
.govuk-accordion__section-button {
  font-size: 1em;
}

@media only screen and (max-width: $show-medium) and (min-width: $show-mobile) {
  .idsk-search-results--expand .idsk-search-results__list {
    border: none;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: $show-mobile) {
  .idsk-search-results--expand .idsk-search-results__list {
    margin-bottom: 1rem;
    border: none;
  }
}

@media (max-width: 48.0525em) {
  .idsk-header-web__scrolling-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
