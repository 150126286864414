@import "../common/config.scss";

.search {
  padding: 0 1rem;
}

.search span {
  color: #f47738;
}

.search-main {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 30% auto;
  gap: 0 2rem;

  h2 {
    margin: 0;
    padding: 5px 0;
  }
}

@media only screen and (max-width: $show-medium) and (min-width: $show-mobile) {
  .search-main {
    grid-template-columns: auto;
  }
  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

@media only screen and (max-width: $show-mobile) {
  .search-main {
    margin: 0;
    grid-template-columns: auto;
  }
  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .vyhladavanie {
    margin-top: 5rem;
  }
}
