@import "../common/config.scss";

.sluzba-karta-big {
  display: flex;
  flex-direction: column;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.row {
  display: grid;
  grid-template-columns: 25% auto;
  background: #f7f8fb;
  padding: 1rem 2rem;
  p {
    margin: 0;
  }
  img {
    margin-bottom: 5px;
  }
}

.subtitle {
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
}

.key-words {
  display: flex;
  gap: 1rem;
}

.key-word {
  margin: 0;
  padding: 5px 15px;
  gap: 10px;
  background: #0065b3;
  border-radius: 10px;
  color: white;
  font-weight: 700;
  line-height: 20px;
  font-size: 1em;
}

@media only screen and (max-width: $show-medium) and (min-width: $show-mobile) {
  .key-words {
    display: grid;
    grid-template-columns: auto auto auto;
  }
}

@media only screen and (max-width: $show-mobile) {
  .header {
    margin-top: 6rem;
  }

  .row {
    grid-template-columns: auto;
  }

  .subtitle {
    padding: 1rem 0;
  }

  .key-words {
    display: grid;
    grid-template-columns: auto;
  }
}
